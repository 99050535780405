/*
 * A file to store all survey questions and placeholder copy questions.
 */

export default {
  resultsSurvey: {
    question: 'How satisfied are you with using this website?',
    placeholderQuestion:
      'What specific aspects of your experience contributed to your satisfaction or dissatifaction?'
  },

  formsSurvey: {
    question: 'On a 1-5 scale, how satisfied were you with using this website?',
    placeholderQuestion:
      'Please tell us more about your experience. The more details you share, the better'
  },

  faqSurvey: {
    question: 'How satisfied are you with using this website?',
    placeholderQuestion:
      'Do you have any comments or suggestions to help us improve your experience?'
  }
}
