<template>
  <div class="textArea">
    <textarea
      ref="input"
      class="textArea__input"
      :class="{
        'textArea__input--hasValue': modelValue
      }"
      :aria-label="placeholder"
      type="textarea"
      :value="modelValue"
      :placeholder="placeholder"
      @change.prevent="onChange"
    />
  </div>
</template>

<script lang="ts">
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const onChange = (event) => {
      emit('update:modelValue', event.target.value)
    }
    return {
      onChange
    }
  }
}
</script>

<style lang="scss">
.textArea__input {
  height: 144px;
  width: 100%;
  padding: rem(16);
  border-radius: 4px;
  border: solid 1px $grey--light;
  background-color: #fff;
  line-height: 1;
  font-family: $mb-font-text;
  font-size: rem(18);
  color: $black--light;
  resize: none;
}
</style>
